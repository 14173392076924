import { FETCH_OBSERVATORY_STATUS_BEGIN, FETCH_OBSERVATORY_STATUS_FAILURE, FETCH_OBSERVATORY_STATUS_SUCCESS } from '../actions/actionTypes';

const initialState = {
    loading: true,
    error: false, 
    failure: false,
    obs_status: null,
};

export default function obsStatus(state = initialState, action) {
    switch(action.type) {
        case FETCH_OBSERVATORY_STATUS_BEGIN:
            return {
                ...state,
                loading: true,
                error: false, 
                failure: false,
                obs_status: null,
            }
    
        case FETCH_OBSERVATORY_STATUS_SUCCESS:
            var obs_stat = action.payload;
            return {
                ...state,
                loading: false, 
                failure: false, 
                error: false,
                obs_status: obs_stat,
            }
        
        case FETCH_OBSERVATORY_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                failure: true,
                error: action.payload,
                obs_status: null,
            }

        default:
            return state;
    }
}