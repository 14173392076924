import React, {Component} from 'react';
import './threeDImageContainer.scss';
import ReactLoading from 'react-loading';

const skymap_container_element_id = 'threed-viewer-container-element';
const skymap_loader_id = 'skymap-model-loader';
const skymap_size_confirmation_id = 'skymap-model-size-confirmation';
//const model_large_size_threshold = 10E6; // 10MB
const model_large_size_threshold = 1; // 1B override for mobile devices 

const materialConfig = {
  name: '',

  type: 'pbrMetallicGlossiness',

  color: '#600',

  transparent: false,
  alpha: 1,
  alphaCutoff: 0,

  diffuseMap: '',
  normalMap: '',
  parallaxOcclusionScale: 0.01,
  parallaxOcclusionMap: '',

  emission: '#700000',
  emissionIntensity: 0.2,
  emissiveMap: '',

  // Metallic and roughness
  metalness: 1.0,
  roughness: 0.5,
  metalnessMap: '',
  roughnessMap: '',

  // Specular and glossiness
  glossiness: 0.4,
  specularColor: '#333',
  glossinessMap: '',
  specularMap: '',
};

class ThreeDImageContainer extends Component {

  constructor(props) {
    super();
    this.state = {
      viewer: undefined,   
      model_size_bytes: props.model_size_bytes !== undefined ? props.model_size_bytes : 0,
      model_can_load: false,
    }
  }

  /// Add the clay-viewer element to header.
  componentWillMount() {
      this.scr = document.createElement("script");
      this.scr.src = "/js/clay-viewer/clay-viewer.min.js";
      this.scr.charset = "utf-8";
      this.scr.async = true;
      document.body.appendChild(this.scr);

      const self = this;
      this.scr.addEventListener('load', () => { 
        if (self.state.model_size_bytes >= model_large_size_threshold ) {
          document.getElementById(skymap_size_confirmation_id).classList.add('loading');
        } else {
          self.setState({'viewer' : this.init_viewer()});
        }
      });
  }

  /// Remove clay viewer from the header. 
  componentWillUnmount() {
    if(this.state.viewer) {
      this.state.viewer.stop();
      delete this.state.viewer;
    }
    if(this.scr)
        document.body.removeChild(this.scr);
    this.src = undefined;
  }

  load_model(viewer, model_url) {
    console.log("Loading 3D Viewer...");
    // Show the loader element. 
    document.getElementById(skymap_loader_id).classList.add('loading');

    // Load a glTF model
    // Model will be fit in 10x10x10 automatically after load.
    // Return an eventful object.
    viewer.loadModel(model_url, {
      doubleSided: true,
      // Not load texture, setMaterial will do it.
      includeTexture: false
    })
    // Model loaded. not include textures.
    .on('loadmodel', function (modelStat) {
      console.log("Loading Model from Server...");

      // Set camera options.
      viewer.setCameraControl({
          // Alpha is rotation from bottom to up.
          alpha: 10,
          // Beta is rotation from left to right.
          beta: 30,
          distance: 20,
          // Min distance of zoom.
          minDistance: 1,
          // Max distance of zoom.
          maxDistance: 100,

          // Center of target.
          center: [0, 0, 0],

          // If auto rotate.
          autoRotate: true,

          // Degree per second.
          autoRotateSpeed: 30,

          // Direction of autoRotate. cw or ccw when looking top down.
          autoRotateDirection: 'cw',

          // Start auto rotating after still for the given time
          autoRotateAfterStill: 30
      });
      viewer.enablePicking();

      // This is super hacky, but it works for now. 
      var matName = Object.getOwnPropertyNames(viewer._materialsMap)[0];
      console.log("Setting Material...");
      viewer.setMaterial(matName, materialConfig);

      viewer.start();

      // Add the resize handler to the container element. 
      window.addEventListener('resize', function () { viewer.resize(); });
    })
    .on('ready', function () {
      // Hide the loader now that we have the model. 
      document.getElementById(skymap_loader_id).classList.remove('loading');
      console.log('All loaded including textures.');
    })
    .on('error', function () {
      console.log('Model load error');
    });
  }

  init_viewer() {
    var viewer = new global.ClayViewer(document.getElementById(skymap_container_element_id), {
        // Full config at
        // https://github.com/pissang/clay-viewer/blob/master/src/defaultSceneConfig.js
        devicePixelRatio: 1,
        textureFlipY: false,
        // Enable shadow
        shadow: true,
        shadowQuality: 'medium',
        // Environment panorama texture url.
        //environment: '/assert/texture/milkyway-constellations.hdr',
        //environment: 'auto',
        ground : {
          show: true,
          grid: true,
        },
        mainLight: {
            // If enable shadow of main light.
            shadow: true,
            // Quality of main light shadow. 'low'|'medium'|'high'|'ultra'
            shadowQuality: 'low',
            // Intensity of main light
            intensity: 0.8,
            // Color of main light
            color: '#fff',
            // Alpha is rotation from bottom to up.
            alpha: 45,
            // Beta is rotation from left to right.
            beta: 45,
        },
        // Configuration of secondary light
        secondaryLight: {
            // If enable shadow of secondary light.
            shadow: true,
            shadowQuality: 'medium',
            // Intensity of secondary light. Defaultly not enable secondary light.
            intensity: 0.5,
            // Color of secondary light.
            color: '#fff',
            alpha: -45,
            beta: 180,
        },
        // Configuration of tertiary light
        tertiaryLight: {
            // If enable shadow of tertiary light.
            shadow: false,
            shadowQuality: 'medium',
            // Intensity of secondary light. Defaultly not enable secondary light.
            intensity: 0.3,
            // Color of tertiary light.
            color: '#fff',
            alpha: 89,
            beta: 0,
        },
        ambientCubemapLight: {
            exposure: -2.0,
            // Intensity of diffuse radiance.
            diffuseIntensity: 0.1,
            // Intensity of specular radiance.
            specularIntensity: 2.0,
            texture: '/assets/texture/milkyway-cubemap-large.hdr'
        },
        ambientLight: {
            // ambient light intensity.
            intensity: 0.1,
            // ambient light color.
            color: '#fff'
        },
        postEffect: {
            // Enable post effect
            enable: true,
            bloom: {
                // Enable bloom
                enable: true,
                intensity: 0.1
            },
            screenSpaceAmbientOcculusion: {
                // Enable screen space ambient occulusion
                enable: true,
                // Sampling radius in work space.
                // Larger will produce more soft concat shadow.
                // But also needs higher quality or it will have more obvious artifacts
                radius: 1.5,
                // Quality of SSAO. 'low'|'medium'|'high'|'ultra'
                quality: 'medium',
                // Intensity of SSAO
                intensity: 1,
            },
            screenSpaceReflection: {
              enable: false,
                // If physically corrected.
                physical: false,
                // Quality of SSR. 'low'|'medium'|'high'|'ultra'
                quality: 'medium',
            },
            colorCorrection: {
              // If enable color correction
              enable: true,
              exposure: 0.5,
              brightness: 0,
              contrast: 1,
              saturation: 1,
            }
        }
    });

    return viewer;
  }

	render() {
    const {viewer} = this.state;
    /// Now load the model and start. 
    if (viewer) {
      this.load_model(viewer, this.props.model);
    }

    /// <p className='skymap-model-size-warning-text'>Warning: this skymap has a large file size ({(this.state.model_size_bytes / 1E6).toFixed(2)} MB). </p>
		return (
      <div className="skymap threeDimage-container">
        <div className="skymap-model-size-warning" id={skymap_size_confirmation_id}>
          <div>
            <p className='skymap-model-size-warning-text'>This skymap is {(this.state.model_size_bytes / 1E6).toFixed(2)} MB. </p>
            <p className='skymap-model-size-warning-subtitle'>Mobile devices and older computers may have some difficulty rendering this map.</p>
            <p className='skymap-model-size-warning-subtitle'>This map will not work with Google Chrome on Android. Firefox on Android is known to work. We are aware of this issue and are working on a fix.</p>
            <p className='skymap-model-size-warning-subtitle'>Would you like to continue loading?</p>
            <button type="button" className="btn btn-outline-light skymap-model-size-warning-button" onClick={ () => { document.getElementById(skymap_size_confirmation_id).classList.remove('loading'); this.setState({'viewer' : this.init_viewer()}); } }>Proceed</button>
          </div>
        </div>
        <div className="skymap-model-loader" id={skymap_loader_id}>
          <div>
            <ReactLoading className='skymap-model-loader-spinner' color='#3DACFF' type='spin' height={20} width={60} />
            <p className='skymap-model-loader-text'>Loading Model ({(this.state.model_size_bytes / 1E6).toFixed(2)} MB)</p>
          </div>
        </div>
        <div id={skymap_container_element_id} className="threeD-viewer-container"></div>
      </div>

		)
	}
}

export default ThreeDImageContainer;
