import React, { Component } from 'react';
import './Menu.scss'; // Import css modules stylesheet as styles
import {Link} from 'react-router-dom';
import ObsStatus from '../components/obsStatus';
import {fetchObsStatusFromLIGO} from '../redux/actions/fetchObsStatus'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import {
	DETECTOR_OFFLINE,
	DETECTOR_SCIENCE,
	DETECTOR_MAINTENANCE,
	DETECTOR_UNKNOWN
} from '../obs_status';

// This is the test json
library.add(faBars)

class Menu extends Component {

	constructor(props) {
		super(props);
		this.state = {
			'menu_open' : false,
			'update_mobile_obs_status' : false,
			'update_obs_status' : false,
			'update': false,
		}

		this.check_for_updates();
		this.setState({ interval: setInterval(() => this.check_for_updates(), 60000) }); // Obs status refreshes every 60 seconds. 
	}

	check_for_updates() {
        // Check that the obs status is cached and that it has not expired. 
		const {obs_status} = this.props;
		if (!obs_status || obs_status.is_expired()) {
			this.props.get_obs_status(); 
		}
	}

	handleCheckboxChange = event => {
		this.setState({ menu_open: event.target.checked, update_mobile_obs_status: event.target.checked, update_obs_status: event.target.checked })
		
	}

	get_circle_style(status) {
		var circle_style = "obs-status-circle"

		if (status === DETECTOR_SCIENCE) {
			circle_style += " obs-status-green-cricle";
		}
		if (status === DETECTOR_MAINTENANCE) {
			circle_style += " obs-status-amber-circle";
		}
		if (status === DETECTOR_OFFLINE) {
			circle_style += " obs-status-red-circle";
		}

		return circle_style;
	}

	get_status_circle(status, text = undefined) {
		return (
			<div className="menu-status-circle-container">
				<div className={this.get_circle_style(status)}>
					<span style={{ 'whiteSpace' : 'nowrap'}}>{ !text ? "" :  text }</span>
				</div>
			</div>
		)
	}

	menu_elements() {
		return (
			<ul className='menu'>
				<Link className='list-item' onClick={() => this.close_menu()} to='/'>
					<li>Home</li>
				</Link>
				<Link className='list-item' onClick={() => this.close_menu()} to='/latest'>
					<li>Latest</li>
				</Link>
				<Link className='list-item' onClick={() => this.close_menu()} to='/alerts'>
					<li>All Alerts</li>
				</Link>
				<Link className='list-item' onClick={() => this.close_menu()} to='/about'>
					<li>About</li>
				</Link>
				<Link className='list-item' onClick={() => this.close_menu()} to='/contact'>
					<li>Contact</li>
				</Link>
			</ul>
		)
	}
	
	close_menu() {
		this.setState({ 'menu_open' : false });
	}

	status_icons() {
		const {obs_status, loading, failed} = this.props;
		if (loading || failed || obs_status === undefined || obs_status === null) {
			return (
				<div id="menu-network-status-icon">
					<span className="menu-network-status-text">Network Status: &nbsp;</span>
					{this.get_status_circle(DETECTOR_UNKNOWN, 'G1')}
					{this.get_status_circle(DETECTOR_UNKNOWN, 'H1')}
					{this.get_status_circle(DETECTOR_UNKNOWN, 'L1')}
					{this.get_status_circle(DETECTOR_UNKNOWN, "V1")}
					{this.get_status_circle(DETECTOR_UNKNOWN, "K1")}
				</div>
			)
		} else {
			return (
				<label id="menu-network-status-icon" for='obs-status-menu-checkbox'>
					<span className="menu-network-status-text">Network Status: &nbsp;</span>
					{this.get_status_circle(obs_status.g1_colour_code, 'G1')}
					{this.get_status_circle(obs_status.h1_colour_code, 'H1')}
					{this.get_status_circle(obs_status.l1_colour_code, 'L1')}
					{this.get_status_circle(obs_status.v1_colour_code, "V1")}
					{this.get_status_circle(obs_status.k1_colour_code, "K1")}
				</label>
			)
		}
	}

	render() {
		return (
			<div className='menu-container'>
				<Link className='menu-logo' to='/'>
					<img id="header-logo-icon" src="/header-logo-icon.png" alt='Chirp Logo' />
					<p>Chirp</p>
				</Link>
				
				<label id='menu-checkbox-label' htmlFor='menu-checkbox'>
					<FontAwesomeIcon icon="bars" />
				</label>
				<div className='menu-list-container'>
					<input type='checkbox' id='menu-checkbox' checked={this.state.menu_open} onChange={this.handleCheckboxChange}></input>
					<div className="obs-status-mobile-menu-container">
						<ObsStatus update={this.state.update_mobile_obs_status}/>
					</div>
					{this.menu_elements()}
				</div>
				<input type='checkbox' id='obs-status-menu-checkbox' />
				{this.status_icons()}
				<div class='menu-obs-status-container'>
					<ObsStatus update={this.state.update_obs_status}/>
				</div>
			</div>
		);
	}
}

Menu.propTypes = {
	obs_status:PropTypes.object,
    get_obs_status:PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        obs_status: state.obsStatus.obs_status,
        loading: state.obsStatus.loading,
        failed: state.obsStatus.failed,
    }
}

const mapDispatchToProps = dispatch => ({
    get_obs_status: () => dispatch(fetchObsStatusFromLIGO()),
})

const MenuContainer = connect(mapStateToProps, mapDispatchToProps)(Menu);
export default MenuContainer;