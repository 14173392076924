import React, { Component } from 'react';
import './LatestAlert.scss'; // Import css modules stylesheet as styles
import Alert from './Alert';
import { fetchLatestAlertFromAPI } from '../../redux/actions/fetchLatestAlert';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// const API = 'http://aaronwjones.com:8000/'
// const DEFAULT_QUERY ='events/latest';
// const SECOND_QUERY = 'return=detail';

class LatestAlert extends Component {
	componentWillMount() {
		// Check that the alert is cached and that it has not expired. 
		if (!this.props.alert || this.props.alert.is_expired()) {
			this.props.get_latest_alert(); // Get the latest alert.
		}
	}


	render() {
		const { alert, loading, failed } = this.props;
		return (
			<Alert alert={alert} loading={loading} failed={failed} />
		);
	}
}


LatestAlert.propTypes = {
	alert:PropTypes.object,
    get_latest_alert:PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        alert: state.latestAlert.latest_alert,
        loading: state.latestAlert.loading,
        failed: state.latestAlert.failed,
    }
}

const mapDispatchToProps = dispatch => ({
    get_latest_alert: () => dispatch(fetchLatestAlertFromAPI()),
})

const LatestAlertContainer = connect(mapStateToProps, mapDispatchToProps)(LatestAlert);
export default LatestAlertContainer;