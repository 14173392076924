import React, {Component} from 'react';
import './Accessibility.scss';

import PageSegmentContainer from '../../components/PageSegmentContainer'

class Accessibility extends Component {

	introStatement() {
		return (
			<p>At the Institute for Gravitational Wave Astronomy at the University of Birmingham,
			we want to ensure that as many people as possible can use our website,
			and we are working to improve our digital services.
			<br /> <br />
			This accessibility statement covers this website <a href="http://chirp.sr.bham.ac.uk">[chirp.sr.bham.ac.uk]</a>, 
			as a website for publicising gravitational wave alerts. We have several websites,
			mobile applications, and platforms, each of which are being reviewed and have their own accessibility statement. 
			We aim to achieve the recommended government standard for web accessibility <a href="https://www.w3.org/TR/WCAG21/">WCAG 2.1 AA.</a>
			<br /><br />
			As a user with accessible needs, you should be able to:
			<br />
			<ul> 
				<li> change colours, contrast levels and fonts </li>
				<li> zoom in up to 200% without the text spilling off the screen </li>
				<li> navigate most of the website using just a keyboard </li>
				<li> navigate most of the website using speech recognition software </li>
				<li> listen to most of the website using a screen reader </li>
			</ul>
			<br />
			We also aim to make the website text as simple as possible to understand.
			<br /> <br />
			If you have a disability, <a href="https://mcmw.abilitynet.org.uk/"> AbilityNet</a> has advice on making your device easier to use. </p>
		)
	}

	howAccessible() {
		return (	
			<p>We know some parts of this website aren’t fully accessible. We are working on these parts of the site and are 
			committed to producing an accessible site for all users. Those parts with accessibility problems are as follows:
			<br /> <br />
			<ul>
				<li>The colour contrast on some page elements is insufficient to be easily discernible for all users. </li>
				<li>The ‘skip to main content’ link made available to screen reading software is not currently functioning. </li>
				<li>Alternative text is not always applied consistently throughout the site. </li>
				<li>The line height or spacing of text cannot be modified by the user. </li>
			</ul>
			</p>
		)
	}

	cannotAccess() {
		return (	
			<p> 
				If you need information on this website in a different format like accessible PDF, large print, easy read, 
				audio recording or braille, please email <a href="mailto:accessibility@star.sr.bham.ac.uk">accessibility@star.sr.bham.ac.uk</a>
				<br /> <br />
				We’ll consider your request and get back to you within 10 days. 
			</p>
		)
	}

	reportingProblems() {

		return (
			<p>
			We’re always looking to improve the accessibility of this website and are continuing to audit our content.
			If you find something that you are unable to access, or we have failed to identify a barrier, please let us know.
			<br /> <br />
			If you find any problems that aren’t listed on this page or think we’re not meeting accessibility requirements,
			please email <a href="mailto:accessibility@star.sr.bham.ac.uk">accessibility@star.sr.bham.ac.uk</a>
			</p>
		)
	}

	enforcementProcedure() {
		return (
			<p> If you are unhappy with the response you have received from us about your accessibility-related issue,
			 you can make a complaint to the University by emailing
			 <a href="mailto:accessibility-complaint@contacts.bham.ac.uk">mailto:accessibility-complaint@contacts.bham.ac.uk</a>.
		 	<br /> <br />
			The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies
			(Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’).
			If you’re not happy with how the University responds to your complaint, 
			contact the <a href="https://www.equalityadvisoryservice.com/">Equality Advisory and Support Service (EASS)</a>.
			</p>
		)
	}

	technicalInfo() {
		return(
			
			<p>The Institute for Gravitational wave Astronomy at the University of Birmingham is committed
			to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications)
			(No. 2) Accessibility Regulations 2018.
			
			<br /> <br />
			
			This website is partially compliant with the Web Content Accessibility Guidelines version 2.1 AA standard, 
			due to the non-compliances listed below.
			</p>

		)
	}

	nonAccessibleContent() {
		return(
			<p>
				<ol>
					<li> Some images don’t have a text alternative, so the information in them isn’t available to 
						people using a screen reader.
						This doesn’t meet WCAG 2.1 ‘A’ success criterion 1.1.1 (non-text content). 
						We plan to add text alternatives for all images by September 2020.
						 When we publish new content, we’ll make sure our use of images meets accessibility standards. </li>
					 <br />
					<li> Some areas of the site continue to use text represented as images – This doesn’t meet WCAG 2.1 ‘AA’ 
						success criterion 1.4.5
						(images of text). We plan to recreate these images as accessible images/content by September 2020. 
						When we publish new images,
					we will make sure we use accessible content. </li>
					<br />
					<li> Some pages use multiple H1 tags or skip heading levels within the page structure.#
						This doesn’t meet WCAG 2.1 ‘AA’ success criterion 2.4.6 (headings and labels).
						We plan to resolve all these issues by September 2020 and will ensure all new published 
						content follows a consistent page content structure. </li>
					<br />
					<li> Keyboard navigation, including the ability to tab easily through content on some web pages 
						is not logical and intuitive. We are currently auditing our website and will address these pages
						 as we find them. This doesn’t meet WCAG 2.1 ‘A’ success criterion 2.1.1 (keyboard access). 
						 We will be addressing this issue by September 2020. </li>
					<br />
					<li> Bypass blocks, specifically the ‘skip to content’ option is currently an issue on large parts 
						of the birmingham.ac.uk website. This doesn’t meet WCAG 2.1 ‘A’ success criterion 2.4.1 (bypass blocks). 
						We will be addressing this issue by September 2020. </li>
					<br />
					<li> Colour palette: Colour contrast is insufficient on some elements across the site and colour
						 use on some elements (e.g. links) were inconsistent. This doesn’t meet WCAG 2.1 ‘AA’ success 
						 criterion 1.4.3 (contrast (minimum)). We will be addressing this issue by September 2020. </li>
				</ol>
				<br />
				The site also contains a range of third party content and functionality. 
				This may direct you to a related service, or partner we work with, where we are not responsible for the
				accessibility of this content.
			</p>

		)
	}

	nonCompliance() {
		return(
			<p>
				<b className='title'> Disproportionate burden </b> <br /> <br />
				<b> Online maps:  </b> 	We will try and ensure online maps are as accessible as possible, 
				but online maps are currently exempt from meeting the accessibility regulations.
				<br /> <br />
			</p>

		)

	}

	howTested(){
		return(
			<p>
				This website was last tested on 20 October 2019 and we continue to audit. 
				The test was carried out by members of the Institute for Gravitational Wave Astronomy using a selection of automated tools,
				s well as manual checks.
				We tested all the pages on the website
			</p>
		)

	}

	improving() {
		return(
			<p>
				Our accessibility roadmap, summarised below, shows how and when we plan to improve accessibility on this site. 
				This roadmap is subject to change, and the site will continue to be audited and adjusted.
				<br /> <br /> 
				We aim that the following changes will be made:
				<br />
				<ul>
					<li> We will remove out of date elements, custom code and features by September 2020 </li>
					<li> We will update our alt-text by September 2020 </li>
				</ul>
				<br />
				This statement was prepared on 27 September 2019. It was last updated on 20 October 2019.
			</p>

		)
	}


	render() {

		return (
			<div>
				<div className="container">
					<h1 className="about-title">Accessibility Statement for Chirp.</h1>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.introStatement()} title="" image={''} /></div>
				</div>
				
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.howAccessible()} title="How accessible this website is" image={''} /></div>
				</div>
				
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.cannotAccess()} title="What to do if you can’t access parts of this website" image={''} /></div>
				</div>
				
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.reportingProblems()} title="Reporting accessibility problems with this website" image={''} /></div>
				</div>
				
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.enforcementProcedure()} title="Enforcement procedure" image={''} /></div>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.technicalInfo()} title="Technical information about this website’s accessibility" image={''} /></div>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.nonAccessibleContent()} title="Non accessible content" image={''} /></div>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.nonCompliance()} title="Non compliance with the accessibility regulations" image={''} /></div>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.howTested()} title="How we tested this website" image={''} /></div>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.improving()} title="What we’re doing to improve accessibility" image={''} /></div>
				</div>
			</div>
			)

	}


}

export default Accessibility;