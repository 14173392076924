import React, {Component} from 'react';

class AladinLite extends Component {

    componentWillMount() {

        this.scr = document.createElement("script");
        this.scr.src = "//aladin.u-strasbg.fr/AladinLite/api/v2/latest/aladin.min.js";
        this.scr.charset = "utf-8";
        this.scr.async = true;
        document.body.appendChild(this.scr);

        this.scr.addEventListener('load', this.initAladin); 

        this.css = document.createElement("link");
        this.css.href = "//aladin.u-strasbg.fr/AladinLite/api/v2/latest/aladin.min.css";
        this.css.rel = "stylesheet";
        document.body.appendChild(this.css);
    }

    componentWillUnmount() {
        if(this.src)
            document.body.removeChild(this.src);
        if(this.css)
            document.body.removeChild(this.css);
        
        this.src = undefined;
        this.css = undefined;
    }

    initAladin() {
        console.log("Initialising Aladin Skymap...");
        this.aladin = window.A.aladin('#aladin-lite-div', {survey: 'P/Mellinger/color', fov:120});
    }

    render() {
        return (
            <AladinLiteContents onLoad={() => this.initAladin()} />
        )
    } 
}

class AladinLiteContents extends Component {

    render() {
        return (
            <div style={{width: '100%', height: '100%', zIndex: "999"}}>
                <div className="skymap" id="aladin-lite-div" style={{width: '100%', height: '100%'}}></div>
            </div>
        )
    }
}

export default AladinLite;