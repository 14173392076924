import React, {Component} from 'react';
import { CHIRP_PRODUCTION_DOMAIN } from '../consts';
import "./ChirpMoved.scss";

class ChirpMoved extends Component {

    render() {
        /* This shows the announcement of Chirp moving to the University of Exeter. This should be removed when no longer required. */
		var href = window.location.href;
		var current_domain = href.split('/')[2];

		// Check if we are on the production domain and not in development mode -- then show a notice that Chirp has moved and point to the new domain. 
		if (href.indexOf(CHIRP_PRODUCTION_DOMAIN) === -1 && !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
			var new_url = "http://" + CHIRP_PRODUCTION_DOMAIN;
			return (
				<div class="alert alert-primary m-3" role="alert">
					<h4 className="alert-heading">Chirp has Moved</h4>
					Chirp is now maintained by the <a href="https://physics-astronomy.exeter.ac.uk/research/astrophysics/">University of Exeter Astrophysics Group</a>, and has moved to <a href={new_url}>{new_url}</a>. This site (at {current_domain}) is no longer updated. <br />

					<a href={new_url} className='chirp-moved-button-link'>
						<button type="button" className="btn btn-block btn-primary btn-lg mt-4">Go to new site</button>
					</a>
				</div>
			)
		} else {
			return (<div></div>);
		}
	}
}

export default ChirpMoved;