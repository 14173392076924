import React from 'react';
import ReactLoading from 'react-loading';

import './Loading.scss';

const Loading = ({ type, color }) => (
    <div className='loading-container'>
        <ReactLoading type={type} color={color} height={20} width={60} />
    </div>
);
 
export default Loading;