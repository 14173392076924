import fetch from 'cross-fetch';

const OBS_STATUS_API_ENDPOINT = '/api/detector_status/latest';
export const CACHE_TIME = 60 * 1000; // 1 minute

export const DETECTOR_OFFLINE = "OFFLINE";
export const DETECTOR_SCIENCE = "SCIENCE";
export const DETECTOR_MAINTENANCE = "MAINTENANCE";
export const DETECTOR_UNKNOWN = "UNKNOWN";
export const detector_states = [DETECTOR_OFFLINE, DETECTOR_SCIENCE, DETECTOR_MAINTENANCE, DETECTOR_UNKNOWN];

class ObsStatus {
    constructor() {
        this.t_fetched = new Date(Date.now());
        this.t_updated = new Date(Date.now());
        // GEO 600
        this.g1_colour_code = DETECTOR_UNKNOWN;
        this.g1_text = '';
        this.g1_duration = null;
        // HANFORD
        this.h1_colour_code = DETECTOR_UNKNOWN;
        this.h1_text = '';
        this.h1_duration = null;
        // LIVINGSTON
        this.l1_colour_code = DETECTOR_UNKNOWN;
        this.l1_text = '';
        this.l1_duration = null;
        // VIRGO
        this.v1_colour_code = DETECTOR_UNKNOWN;
        this.v1_text = '';
        this.v1_duration = null;
        // KAGRA
        this.k1_colour_code = DETECTOR_UNKNOWN;
        this.k1_text = '';
        this.k1_duration = null;
    }
        
    is_expired() {
        const test_date = this.t_fetched.getTime() + CACHE_TIME;
        if (test_date >= Date.now())
            return false;
        else
            return true;
    }

    get_colour_code(cc) {
        for(var code in detector_states) {
            if (cc in code)
                return code
        }
    }

    from_api(o) {
        
        this.g1_colour_code = o['GEO'].state.toUpperCase();
        this.g1_text = o['GEO'].state;
        this.g1_duration = o['GEO'].duration === '' ? '-- : --' : o['GEO'].duration;

        this.h1_colour_code = o['LHO'].state.toUpperCase();
        this.h1_text = o['LHO'].state;
        this.h1_duration = o['LHO'].duration === '' ? '-- : --' : o['LHO'].duration;

        this.l1_colour_code = o['LLO'].state.toUpperCase();
        this.l1_text = o['LLO'].state;
        this.l1_duration = o['LLO'].duration === '' ? '-- : --' : o['LLO'].duration;

        this.v1_colour_code = o['Virgo'].state.toUpperCase();
        this.v1_text = o['Virgo'].state;
        this.v1_duration = o['Virgo'].duration === '' ? '-- : --' : o['Virgo'].duration;

        this.k1_colour_code = o['KAGRA'].state.toUpperCase();
        this.k1_text = o['KAGRA'].state;
        this.k1_duration = o['KAGRA'].duration === '' ? '-- : --' : o['KAGRA'].duration;

        this.t_updated = new Date(o['UPDATED']);
    }
}

export function get_obs_status() {
    var promise = new Promise((resolve, reject) => {
        fetch(OBS_STATUS_API_ENDPOINT)
        .then(response => response.json())
        .then(response => {
            var obs_status = new ObsStatus();
            obs_status.from_api(response);
            resolve(obs_status);
        }).catch(err => reject(err))
    });

    return promise;
}