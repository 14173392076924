import fetch from 'cross-fetch';

const CACHE_TIME = 60 * 1000; // 1 minute

const API_BASE_URL = '/api/';
const API_EVENT_URI = "events/"; 
const API_LATEST_EVENT_URI = "events/latest";

const API_EVENTS_LIST_ARGS = "";
const API_LATEST_EVENT_ARGS = ""; 
const API_EVENT_ARGS = ""; 

const FAR_MONTH_CUTOFF = 3600.0 * 24.0 * (365.25 / 12.0);
const FAR_YEAR_CUTOFF = 3600.0 * 24.0 * 365.25;
const AGE_OF_EARTH = 4.543e9;
const AGE_OF_UNIVERSE = 1.3799e10; // From Planck / LambdaCDM model. 

class Alert  {
    constructor() {
        this.distmean = 0;
        this.diststd = 0;
        this._far = 0;
        this.farUnit = "";
        this.graceid = "";
        this.hasns = 1;
        this.hasremnant = 0;
        this.massgap = 0;
        this.pbbh = 0;
        this.pbns = 0;
        this.pnsbh = 0;
        this.pterrestrial = 0;
        this.retracted = false;
        this.t_observed = new Date(Date.now());
        this.t_received = new Date(Date.now());
        this.t_updated = new Date(Date.now());
        this.t_fetched = new Date(Date.now());
        this.skymaps ="";
    }

    get mostlikely() {
        return this.get_type_text();
    }

    get status_string() { 
        return this.get_status_string();
    }  
    
    set_items(o) {
        this.distmean = o.distmean;
        this.diststd = o.diststd;
        this._far = o.far;
        this.graceid = o.graceid;
        this.hasns = o.hasns;
        this.hasremnant = o.hasremnant;
        this.massgap = o.massgap;
        this.pbbh = o.pbbh;
        this.pbns = o.pbns;
        this.pnsbh = o.pnsbh;
        this.pterrestrial = o.pterrestrial;
        this.retracted = o.retracted;
        this.published = o.published;
        this.t_observed = new Date(o.t_observed);
        this.t_received = new Date(o.t_received);
        this.role = o.role;
        this.instruments = o.instruments;
        this.pipeline = o.pipeline;
        this.retracted = o.retracted;
        this.skymapurl = o.skymapurl;
        this.t_updated = new Date(o.t_updated);
        this.duration = o.duration;
        this.eventpage = o.eventpage;
        this.centralfreq = o.centralfreq;
        this.fluecence = o.fluecence;
        this.type = o.type;
        this.skymaps=o.skymaps;
        this.t_fetched = new Date(Date.now());
    }

    is_expired() {
        const test_date = this.t_fetched.getTime() + CACHE_TIME;
        if (test_date >= Date.now())
            return false;
        else
            return true;
    }

    most_likely_type() {
        var probs = [this.pbbh,this.pbns,this.pnsbh,this.pterrestrial,this.massgap];
        const objType = ['bbh','bns','nsbh','noise','massgap']
        var most_likely = objType[probs.indexOf(Math.max(...probs))];
        
        if (this.retracted === true){
            most_likely = "noise"
        }
        return most_likely;
    }

    is_retracted() {
        if (this.retracted === true) {
            return true;
        } else {
            return false;
        }
    }

    is_published() {
        if (this.published === true) {
            return true;
        } else {
            return false;
        }
    }

    get_status_string() {
        if (this.is_retracted()) {
             return 'Alert Retracted'; 
        } else {
            if (this.is_published())
            {
                return 'Alert Published';
            } else {
                return 'Alert Candidate';
            }
        }
    }

    get_type_text() {
        var type = "";
        var type_code="";
        if (this.type==='Burst') {
            type_code = 'Burst';
        } else{
            type_code = this.most_likely_type();
        } 
        switch (type_code) {
            case 'bbh' : 
                type = "Binary Black Hole System";
                break;

            case 'bns' :
                type = "Binary Neutron Star System";
                break;

            case 'nsbh':
                type = "Neutron Star - Black Hole Binary System";
                break;
    
            case 'noise':
                type = "Terrestrial Noise";
                break;
    
            case 'massgap':
                type = "Binary with at least one component between 3 & 5 solar masses (mass gap)";
                break;
            case 'Burst':
                type ="Burst Candidate";
                break;
            default:
                type = "Unknown";
                break;
          }
    
        return type;
    }

    // Returns the raw value for FAR in Hz.
    get far() {
        return this._far;
    }
    
    // Returns the value for FAR in a textual representation, including units. The best way to represent it is decided by the function. 
    get far_text() {
        return this.get_far_text();
    }

    get far_simple_text() {
        return this.get_simple_far_text();
    }

    output_far_units(far) {
        if (far > 1E3) {
            // Let's convert to scientific notation
            return far.toExponential(3);
        } else {
            // Leave as-is, but round to 4DP anyway. 
            return far.toFixed(1);
        }
    }

    get_far_text() {
        var pre = "1 per";

        if (( 1.0 / this._far ) / FAR_YEAR_CUTOFF >= AGE_OF_UNIVERSE ) {
            var far_universe_age = ( 1.0 / this._far ) / (FAR_YEAR_CUTOFF * AGE_OF_UNIVERSE);
            return `${pre} ${this.simplify_years(far_universe_age)} times the age of the Universe`;
        } 

        if (( 1.0 / this._far ) / FAR_YEAR_CUTOFF >= 1E9 ) {
            var far_earth_age = ( 1.0 / this._far ) / (FAR_YEAR_CUTOFF * AGE_OF_EARTH);
            return `${pre} ${this.simplify_years(far_earth_age)} times the age of Earth`;
        }

        if ( 1.0 / this._far > FAR_YEAR_CUTOFF ) {
            var far_yr = ( 1.0 / this._far ) / FAR_YEAR_CUTOFF;
            return `${pre} ${this.output_far_units(far_yr)} years`;
        }

        if ( 1.0 / this._far > FAR_MONTH_CUTOFF ) {
            var far_mnth = ( 1.0 / this._far ) / FAR_MONTH_CUTOFF;
            return `${pre} ${this.output_far_units(far_mnth)} months`;
        }

        var far_per_year = this._far * 3600.0 * 24 * 365.25;
        return `${far_per_year.toFixed(2)} per year`;
    }

    get_simple_far_text() {
        var pre = "1 per";

        if (( 1.0 / this._far ) / FAR_YEAR_CUTOFF >= AGE_OF_UNIVERSE ) {
            var far_universe_age = ( 1.0 / this._far ) / (FAR_YEAR_CUTOFF * AGE_OF_UNIVERSE);
            return `${pre} ${this.simplify_years(far_universe_age)} times the age of the Universe`;
        } 

        if (( 1.0 / this._far ) / FAR_YEAR_CUTOFF >= 1E9 ) {
            var far_earth_age = ( 1.0 / this._far ) / (FAR_YEAR_CUTOFF * AGE_OF_EARTH);
            return `${pre} ${this.simplify_years(far_earth_age)} times the age of Earth`;
        }

        if ( 1.0 / this._far > FAR_YEAR_CUTOFF ) {
            var far_yr = ( 1.0 / this._far ) / FAR_YEAR_CUTOFF;
            return `${pre} ${this.simplify_years(far_yr)} years`;
        }

        if ( 1.0 / this._far > FAR_MONTH_CUTOFF ) {
            var far_mnth = ( 1.0 / this._far ) / FAR_MONTH_CUTOFF;
            return `${pre} ${this.output_far_units(far_mnth)} months`;
        }

        var far_per_year = this._far * 3600.0 * 24 * 365.25;
        return `${far_per_year.toFixed(2)} per year`;
    }

    simplify_years(far) {
        var logfar = Math.log10(far);

        if (logfar < 3.0)
            return `${far.toFixed(2)}`;
        if (logfar < 6.0)
            return `${(far / 1E3).toFixed(2)} thousand`;
        if (logfar < 9.0)
            return `${(far / 1E6).toFixed(2)} million`;
        if (logfar < 12.0)
            return `${(far / 1E9).toFixed(2)} billion`;
        if (logfar < 15.0)
            return `${(far / 1E12).toFixed(2)} trillion`;
        if (logfar < 18.0)
            return `${(far / 1E15).toFixed(2)} quadrillion`;
        else
            return `${(far / 1E18).toFixed(2)} quintillion`;
    }
}


export function get_alert(alert_id) {
    
    var promise = new Promise((resolve, reject) => {
        fetch(API_BASE_URL + API_EVENT_URI + alert_id + "?" + API_EVENT_ARGS, { credentials: 'include'})
        .then(response => response.json())
        .then(items => {
            var alert = new Alert();
            alert.set_items(items[0]);
            resolve(alert);
        })
        .catch(err => reject(err));
    });
    return promise;
}

export function get_latest_alert() {
    var promise = new Promise((resolve, reject) => {
        fetch(API_BASE_URL + API_LATEST_EVENT_URI + "?" + API_LATEST_EVENT_ARGS, { credentials: 'include'})
        .then(response => response.json())
        .then(items => {
            var alert = new Alert();
            alert.set_items(items[0]);
            resolve(alert);
        })
        .catch(err => reject(err));
    });
    return promise;
}

export function get_alert_list(offset = 0) {

    var args = API_EVENTS_LIST_ARGS
    if ( offset > 0 )
        args += `&offset=${offset}`;

    var promise = new Promise((resolve, reject) => {
        fetch(API_BASE_URL + API_EVENT_URI + '?' + args, { credentials: 'include'})
        .then(response => response.json())
        .then(items => {
            var alerts = items.map((item, _) => {
                var alert = new Alert();
                alert.set_items(item);
                return alert;
            });
            resolve(alerts);
        })
        .catch(err => reject(err));
    });

    return promise
}

/*
 * Return a dict of alerts that are grouped by period of time. 
 * The keys are the time periods, and the values are lists of alerts.
 * The paucity of the time alerts is automatically scaled based on the
 * provided `nmin_alert` parameter, which determined the minimum number
 * of alerts per segment. 
 * We scale from segments of days, months, or years. 
 * We assume that the alerts list is sorted by t_observed in descending order.
 */
export function segment_alert_list(alerts, average_alerts_per_segment = 5) {
    
    // Get a list of years that are in the t_observed range.
    var years = alerts.map((alert, _) => alert.t_observed.getFullYear());
    var year_set = new Set(years);
    var segments_list = [...year_set].map((year, _) => {
        // Get the first and last date of the year specified in the current iteration.
        var curr_year_start = new Date(Date.UTC(year, 0, 1));
        var curr_year_end = new Date(Date.UTC(year, 11, 31));
        var curr_year_alerts = alerts.filter((alert, _) => alert.t_observed >= curr_year_start && alert.t_observed <= curr_year_end);
        
        // Split them down into months and see if we have enough to meet the minimum.
        var months = curr_year_alerts.map((alert, _) => alert.t_observed.getUTCMonth());
        var month_set = new Set(months);
        var average_alerts_per_month = curr_year_alerts.length / month_set.size;

        if (average_alerts_per_month >= average_alerts_per_segment) {
            // Split down into months.
            var month_segments = [...month_set].map((month, _) => {
                // Calculate the range of the month in UTC, and filter out the alerts that fill within that month.
                var curr_month_start = new Date(Date.UTC(year, month, 1));
                var curr_month_end = new Date(Date.UTC(year, month + 1, 1));

                var curr_month_alerts = curr_year_alerts.filter((alert, _) => alert.t_observed >= curr_month_start && alert.t_observed < curr_month_end);
                var days = curr_month_alerts.map((alert, _) => alert.t_observed.getUTCDate());
                var day_set = new Set(days);
                var average_alerts_per_day = curr_month_alerts.length / day_set.size;

                if (average_alerts_per_day >= average_alerts_per_segment) {
                    // Split down into days.
                    var day_segments = [...day_set].map((day, _) => {
                        // Calculte the range of the day in UTC, and filter out the alerts that fill within that day. 
                        var curr_day_start = new Date(Date.UTC(year, month, day));
                        var curr_day_end = new Date(Date.UTC(year, month, day + 1));
                        var curr_day_alerts = curr_month_alerts.filter((alert, _) => alert.t_observed >= curr_day_start && alert.t_observed < curr_day_end);
                        
                        var label = get_alerts_segment_label(curr_day_start, curr_day_end);
                        return { [label] : curr_day_alerts };
                    });

                    // Merge the list of dicts into a single dict.
                    var day_segments_dict = Object.assign({}, ...day_segments);
                    return day_segments_dict;
                } else {
                    // We don't have enough per day to split down further, so return the alerts for this month. 
                    var label = get_alerts_segment_label(curr_month_start, curr_month_end);
                    return { [label] : curr_month_alerts };
                }
            });

            // Merge the list of dicts into a single dict.
            var month_segments_dict = Object.assign({}, ...month_segments);
            return month_segments_dict;
        } else {
            // It's not likely that we get to this point, but if we don't have enough per month, just return those from the year. 
            var label = get_alerts_segment_label(curr_year_start, curr_year_end);
            return { [label] : curr_year_alerts };  
        }     
    });

    // Merge the list of dicts into a single dict.
    var segments = Object.assign({}, ...segments_list);
    
    return segments;
}

export function get_alerts_segment_label(start_range, end_range) {
    var duration = (end_range - start_range) / 1000;    
    if (duration <= 24 * 3600) {
        // Format as day / month / year.
        return start_range.toLocaleString('default', { day: 'numeric' }) + " " + start_range.toLocaleString('default', { month: 'short' }) + " " + start_range.getFullYear();
    } else if (duration < 365.25 * 24 * 3600) {
        // Format as month / year.
        return start_range.toLocaleString('default', { month: 'short' }) + " " + start_range.getFullYear();
    } else {
        // Format as year.
        return start_range.getFullYear();
    }
}

export default Alert;
