import React, {Component} from 'react';
import './obsStatus.scss';
import Loading from '../App/Loading';

import {fetchObsStatusFromLIGO} from '../redux/actions/fetchObsStatus'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt, faCheck, faWrench, faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons'

import {
	DETECTOR_OFFLINE,
	DETECTOR_SCIENCE,
	DETECTOR_MAINTENANCE,
	DETECTOR_UNKNOWN
} from '../obs_status';

const modalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : '25%',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
	},
	overlay : {
		zIndex 				  : '100',
	}
};

// Get the icons in
library.add([faSyncAlt, faCheck, faWrench, faTimes, faQuestion])

class ObsStatusView extends Component {

	constructor(props) {
		super(props)
		this.state = {update: false, isModalVisible: false}
	}

	setModalVisble(visible) {
        this.setState({ isModalVisible: visible });
	}
	
	getModal() {
		return (
            <Modal
                isOpen={this.state.isModalVisible}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={() => this.setModalVisble(false)}
                style={modalStyles}
                className="Modal"
                overlayClassName="Overlay"
                contentLabel="Example Modal"
                >
                    <div className="close-button" onClick={() => this.setModalVisble(false)}>x</div>
                    <div className="modal-content-container">
                    <h2>Network Status</h2>
                    This section displays the current status of the global gravitational wave observatory network. The status of each detector is indicated by the colour and icon shown to the right of it. A guide to these is shown below. The length of time in which the detector has been in this state is shown in hours and minutes (in hh:mm format). 
                    <div style={{lineHeight: '3.5rem', paddingTop: '10px', }}>
                        {this.get_status_row("Online / Science", DETECTOR_SCIENCE, '-- : --')}
                        {this.get_status_row('Maintenance', DETECTOR_MAINTENANCE, '-- : --')}
                        {this.get_status_row('Offline', DETECTOR_OFFLINE, '-- : --')}
                        {this.get_status_row('Unknown Status', DETECTOR_UNKNOWN, '-- : --')}
                    </div>
                </div>
            </Modal>
		)
	}

	componentWillReceiveProps(props) {
		if (this.state.update !== props.update && props.update === true) {
			this.check_for_updates();
		}

		this.setState({ update: props.update });
	}

	check_for_updates() {
        // Check that the obs status is cached and that it has not expired. 
		const {obs_status} = this.props;
		if (!obs_status || obs_status.is_expired()) {
			this.props.get_obs_status(); 
		}
	}
	
	get_circle_style(status) {
		var circle_style = "obs-status-circle"

		if (status === DETECTOR_SCIENCE) {
			circle_style += " obs-status-green-cricle";
		}
		if (status === DETECTOR_MAINTENANCE) {
			circle_style += " obs-status-amber-circle";
		}
		if (status === DETECTOR_OFFLINE) {
			circle_style += " obs-status-red-circle";
		}

		return circle_style;
	}

    
	get_status_icon(status) {
		if (status === DETECTOR_SCIENCE) {
			return(<FontAwesomeIcon className="obs-status-circle-icon" style={{marginRight: '16px'}} icon={"check"} />)
		}
		if (status === DETECTOR_MAINTENANCE) {
			return(<FontAwesomeIcon className="obs-status-circle-icon obs-status-maintenance-duration-text" style={{marginRight: '17px'}} icon={"wrench"} />)
		}
		if (status === DETECTOR_OFFLINE) {
			return(<FontAwesomeIcon className="obs-status-circle-icon" style={{marginRight: '22px'}} icon={"times"} />)
		}
		return(<FontAwesomeIcon className="obs-status-circle-icon" style={{marginRight: '22px'}} icon={"question"} />)
    }

	get_status_row(name, status, duration, loading=false) {
		return (
			<div className="obs-status-row-container" styles={loading ? "opacity: 0.5; " : ""}>
				<h3 className="obs-status-name-text">{name}</h3>
                <div className="obs-status-circle-container">
                    <div className={this.get_circle_style(status)}>
                        {this.get_status_icon(status)}
                        <div className={status === DETECTOR_MAINTENANCE ? "obs-status-duration-text obs-status-maintenance-duration-text" : "obs-status-duration-text"}>{duration}</div>
                    </div>
                </div>
			</div>
		)
	}

	convertDate(inputDate) {
		var d = new Date(inputDate);
		var output = [this.padDateZeros(d.getUTCHours()) + ':' + this.padDateZeros(d.getUTCMinutes()) + ' ' + d.toDateString() + ' UTC'];
		return output
	}

	padDateZeros(inputNumber) {
		if (inputNumber<10) {
				return '0'+ inputNumber
			}
			else {
				return inputNumber
			}
	}

	render() {
		const {obs_status, loading, failed} = this.props;

		if( loading ) {
			return (
				<div className='obs-status-container'>
					{this.getModal()}
					<div>
						<h1 className='obs-status-title-text'>Network Status </h1>
                        <div className="obs-status-header-icons">
                            <FontAwesomeIcon className="obs-status-header-icon" onClick={() => this.setModalVisble(true)} icon="info-circle" /> 
                            <FontAwesomeIcon className="obs-status-header-icon" icon="sync-alt" />
                        </div>
	                </div>	
					<div className="obs-status-loading-indicator" >
                        <Loading color='white' type='spin' />
					</div>

                    {this.get_status_row('GEO 600', DETECTOR_UNKNOWN, '--:--', true)}
					{this.get_status_row('LIGO Hanford', DETECTOR_UNKNOWN, '--:--', true)}
					{this.get_status_row('LIGO Livingston', DETECTOR_UNKNOWN, '--:--', true)}
					{this.get_status_row('Virgo', DETECTOR_UNKNOWN, '--:--', true)}
					{this.get_status_row('KAGRA', DETECTOR_UNKNOWN, '--:--', true)}
	
					<div className="obs-status-last-updated-container">
						<h3 className="obs-status-last-updated-text">Last Updated: Loading...</h3>
					</div>
				</div>
			)
		}

		if ( failed || obs_status === undefined || obs_status === null) {
			return (
				<div className='obs-status-container'>
					{this.getModal()}
					<div>
						<h1 className='obs-status-title-text'>Network Status </h1>
                        <div className="obs-status-header-icons">
	                	    <FontAwesomeIcon className="obs-status-header-icon" onClick={() => this.setModalVisble(true)} icon="info-circle" />   
                            <FontAwesomeIcon className="obs-status-header-icon" onClick={() => this.check_for_updates()} icon="sync-alt" /> 
                        </div> 
	                </div>	
                    {this.get_status_row('GEO 600', DETECTOR_UNKNOWN, '--:--', true)}
					{this.get_status_row('LIGO Hanford', DETECTOR_UNKNOWN, '--:--')}
					{this.get_status_row('LIGO Livingston', DETECTOR_UNKNOWN, '--:--')}
					{this.get_status_row('Virgo', DETECTOR_UNKNOWN, '--:--')}
					{this.get_status_row('KAGRA', DETECTOR_UNKNOWN, '--:--')}
	
					<div className="obs-status-last-updated-container">
						<h3 className="obs-status-last-updated-text">Last Updated: </h3>
					</div>
				</div>
			);
		} else {
			return (
				<div className='obs-status-container'>
					{this.getModal()}
					<div>
						<h1 className='obs-status-title-text'>Network Status </h1>
                        <div className="obs-status-header-icons">
	                	    <FontAwesomeIcon className="obs-status-header-icon" onClick={() => this.setModalVisble(true)} icon="info-circle" />   
                            <FontAwesomeIcon className="obs-status-header-icon" onClick={() => this.check_for_updates()} icon="sync-alt" /> 
                        </div> 
                    </div>	

                    {this.get_status_row('GEO 600', obs_status.g1_colour_code, obs_status.g1_duration)}
					{this.get_status_row('LIGO Hanford', obs_status.h1_colour_code, obs_status.h1_duration)}
					{this.get_status_row('LIGO Livingston', obs_status.l1_colour_code, obs_status.l1_duration)}
					{this.get_status_row('Virgo', obs_status.v1_colour_code, obs_status.v1_duration)}
					{this.get_status_row('KAGRA', obs_status.k1_colour_code, obs_status.k1_duration)}
	
					<div className="obs-status-last-updated-container">
                        <h3 className="obs-status-last-updated-text">Last Updated: {this.convertDate(obs_status.t_updated)}</h3>
					</div>
				</div>
			);
		}
	}
}

ObsStatusView.propTypes = {
	obs_status:PropTypes.object,
    get_obs_status:PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        obs_status: state.obsStatus.obs_status,
        loading: state.obsStatus.loading,
        failed: state.obsStatus.failed,
    }
}

const mapDispatchToProps = dispatch => ({
    get_obs_status: () => dispatch(fetchObsStatusFromLIGO()),
})

const ObsStatusContainer = connect(mapStateToProps, mapDispatchToProps)(ObsStatusView);
export default ObsStatusContainer;