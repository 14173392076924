import {get_alert_list} from '../../alerts';

import {FETCH_ALERT_LIST_BEGIN, FETCH_ALERT_LIST_SUCCESS, FETCH_ALERT_LIST_FAILURE, INVALIDATE_ALERT_LIST} from './actionTypes.js';

export function fetchAlertListBegin() {
    return { type: FETCH_ALERT_LIST_BEGIN };
}


export function fetchAlertListSuccess(alerts) {
    return {
        type: FETCH_ALERT_LIST_SUCCESS,
        payload: alerts,
        time_fetched: new Date(Date.now()),
    }
}

export function fetchAlertListFaliure(err) {
    return { type:FETCH_ALERT_LIST_FAILURE, payload: err, time_fetched: new Date(0)}
}

export function invalidateAlertsList() {
    return { type: INVALIDATE_ALERT_LIST, time_fetched: new Date(0) }; 
}

export function retrieveAlertsPage(offset = 0) {

    return dispatch => {
        dispatch(fetchAlertListBegin());
        get_alert_list(offset)
        .then(response => {
            dispatch(fetchAlertListSuccess(response));
        })
        .catch(err => { 
            dispatch(fetchAlertListFaliure(err));
        })
    }
}