
import React, { Component } from 'react';
import './Footer.scss';

// Import the menu to get the menu elements
//import {Link} from 'react-router-dom';

class Footer extends Component {

    render() {
        return (
            <div className="footer-container">
                <div className="container-fluid">
                    <div className='row'>
						<div className='col-sm col-md-6 col-lg-4 col-xl-3'>
							<a href="https://physics-astronomy.exeter.ac.uk/research/astrophysics/" target='_blank'>
								<img src="/uoe-logo.png" alt="University of Exeter Logo" className='uoe-logo-footer' />
							</a>
						</div>

                        <div className='col-sm col-md-6 col-lg-8 col-xl-9'>
                            <div className='copyright-notice'>
                            	<p>
                                 	Website &copy; {new Date().getFullYear()} - Sam Morrell, Sam Cooper, Aaron Jones, George Smetana, Riccardo Buscicchio

                                </p>
                                <p className="disclaimer"><b>Disclaimer</b>: Chirp uses publicly available GCNs for information, for the latest information visit <a href="https://gracedb.ligo.org/">GraceDB</a> or the event circulars, linked on each event.</p> <p className="disclaimer"> Google Play and the Google Play logo are trademarks of Google LLC. Apple, the Apple logo, iPhone, and iPad are trademarks of Apple Inc., registered in the U.S. and other countries and regions. App Store is a service mark of Apple Inc.</p>

								<a className="access" href="/accessibility">Accessibility</a> &nbsp; - &nbsp;
                            	<a href="https://www.laserlabs.org/privacy_policy.php"> Privacy Policy</a> &nbsp; &nbsp; &nbsp; 
								<a href="https://doi.org/10.5281/zenodo.3525064">
									<img src="https://zenodo.org/badge/DOI/10.5281/zenodo.3525064.svg" alt="DOI" />
								</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
