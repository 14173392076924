import React, { Component } from 'react';
import Modal from 'react-modal';
import './Alert.scss';

import Container from '../../components/container.js';
import ImageContainer from '../../components/imageContainer.js';
import AladinLite from '../../components/AladinLite';
import Loading from '../Loading';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// import { TwitterHashtagButton } from 'react-twitter-embed'; 
import ThreeDImageContainer from '../../components/threeDImageContainer.js';

import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import blueGrey from '@material-ui/core/colors/blueGrey';
import orange from '@material-ui/core/colors/orange';

// This is the test json
library.add(faInfoCircle)

const main_labels = ['Date','Duration (s)','Mean Distance (Mpc)','Distance Range (Mpc)','False Alarm Rate','Most Likely Origin'];
const source_labels = ['BBH','BNS','BH-NS','Noise','MassGap','Remnant']
const other_labels = ['Event Page','Event Type','Event Circulars','Role','Instruments','Last Updated'];

const main_fields = ['t_observed','duration','distmean','distmstd','FAR','mostlikely'];
const source_fields = ['pbbh','pbns','pnsbh','pterrestrial','massgap','hasremnant'];
const other_fields = ['graceid','eventtype','circular','role','instruments','t_updated'];

// This should be set to true if you want to enable image selection. 
const enable_skymap_tabs = false;

// Do we use the AladinLite skymap (true) or the image from GraceDB (false). 
// var use_skymap = false;

// Do we use the 3D skymap 

// Note that the gltf model needs to be in the public folder. I'm using S190426c as an example. Ideally the API would be modified to point to the correct file / folder for the 3D skymap.
// var use_3d_skymap = false;

class Alert extends Component {

  constructor(props){
    super();
    this.changeSkymap=this.changeSkymap.bind(this);

    this.state ={
      use_skymap:false,
      use_3d_skymap:false
    }
  }

  componentDidMount() {
    // Initialise the modal dialog box. 
    Modal.setAppElement('#content');

  }

  get_status_subtitle(alert) {
    if (alert.is_retracted()) {
      return(
        <h3 className='alert-status-subtitle retracted'>{alert.status_string}</h3>    
      )
    } else {
      if (alert.is_published()) {
        return (
          <h3 className='alert-status-subtitle published'>{alert.status_string}</h3>    
        )
      } else {
        return (
          <h3 className='alert-status-subtitle candidate'>{alert.status_string}</h3>    
        )
      }
    }
  }

  get_alert_subtitle_colour(alert) {
    const type_code = alert.most_likely_type();
    switch (type_code) {
        case 'bbh' : 
          return blue['800'];

        case 'bns' :
          return purple['800'];

        case 'nsbh':
          return green['800'];

        case 'noise':
          return orange['800'];

        case 'massgap':
          return blueGrey['800'];

        default:
          return "#000";
      }
}

  get_alert_link(alert) {
    return (
      <a href={alert.eventpage}>
        {alert.graceid}
      </a>
    )
  }

  get_circular_link(alert) {
    const baseUrl = "https://gcn.gsfc.nasa.gov/other/"; 
    const graceIDUrl = baseUrl.concat(alert.graceid,'.gcn3');
    return(
      <a href={graceIDUrl}> Alert History</a>
    )
  }

  get_far_text(alert) {
    return(
      <span title={alert.far_text}>{alert.far_simple_text}</span>
    )
  }

  get_range_text(alert) {
    if (alert.distmean==null || alert.diststd==null) {
      
      return (
        <span>N/A</span>
      )
      
    }
    else {
      return(
        <span>{(alert.distmean - alert.diststd).toFixed(0)} - {(alert.distmean + alert.diststd).toFixed(0)}</span>
      )
    }

    
  }

  get_instruments_item(alert) {
    var insts = [];
    if (alert.instruments.includes("H1"))
      insts.push(<li key="h1"><a href="https://www.ligo.caltech.edu/WA" target="_blank" rel="noopener noreferrer">LIGO Hanford (H1)</a></li>);
    if (alert.instruments.includes("L1"))
      insts.push(<li key="l1"><a href="https://www.ligo.caltech.edu/LA" target="_blank" rel="noopener noreferrer">LIGO Livingston (L1)</a></li>);
    if (alert.instruments.includes("V1"))
      insts.push(<li key="v1"><a href="http://www.virgo-gw.eu/" target="_blank" rel="noopener noreferrer">VIRGO (V1)</a></li>);
    if (alert.instruments.includes("K1"))
      insts.push(<li key="k1"><a href="https://gwcenter.icrr.u-tokyo.ac.jp/en/" target="_blank" rel="noopener noreferrer">KAGRA (K1)</a></li>);
    return (
      <ul id="instrument-list">{insts}</ul>
    )
  }

  convert_probs(key) {
      if (((100*(key)).toFixed(0)) <=1) {
        return "<1";
      }
      else if (((100*(key)).toFixed(0)) >=99) {
        return ">99";
      }
      else {
        return (100*(key)).toFixed(0);
      }
  }

  convert_distance(alert) {

    if (alert.distmean==null) {
      return (
        "N/A"

      )
    }
    else {
      return (
        alert.distmean.toPrecision(3)        
      )
    }

  }

  check_source_type(alert,source_items,source_fields) {
    console.log(alert.type)
    if (alert.type==='Burst') {
      return (<div className='col-md'>{this.get_burst_source_text()}</div>)
    }
    else {
      return(
        <div className='col-md'><Container items={source_items} title='Source Origin Probability' fields={source_fields} labels={source_labels}/></div>
      )
    }

  }

  get_burst_source_text(){
    return (
    <div className="content-Container">
      <h1 className='Container-title'>Source Origin Probability</h1>
      <ul className="propContainer">
      <li className="burst-list-item">
      <span className="burst-list-item-label">
        <span className='burst-list-item-property'>Burst Candidate</span>
         :&nbsp; &nbsp;
        </span>
            <span className="burst-list-item-value"> This event candidate was found by the unmodelled Burst search. While the Binary search looks for gravitational waves which match calculated signals, the Burst search is more flexible, allowing it to find a range of different signals including those from supernovae, magnetar bursts, and cosmic strings, as well as potentially previously unknown sources. Accordingly, there are no probabilities assigned for the source type. </span>
      </li>
      </ul>
     </div>

     )
  }
  changeSkymap(input){
    this.skyHeight = document.getElementsByClassName('content-Container')[0].clientHeight;
    this.skyWidth = document.getElementsByClassName('content-Container')[0].clientWidth;
    console.log(this.skyHeight,this.skyWidth)
    switch(input) {
      case "2D":
        this.setState({
          use_3d_skymap:false,
          use_skymap:false
        });
        break;
      case "orbital":
        this.setState({
          use_3d_skymap:false,
          use_skymap:true
        });
        break;
      case "3D":
        this.setState({
          use_3d_skymap:true,
          use_skymap:false
        });
        break;
      default:
        this.setState({
          use_3d_skymap:false,
          use_skymap:false
        });
        break;
    }
  }

  get_3d_skymap(alert) {
	  var active2d, activeAladin, active3d = "";
    var container = undefined;
    
      if (this.state.use_3d_skymap) {
        var model_size_bytes = alert.skymaps['3D'].default.size ? alert.skymaps['3D'].default.size : 0;
        container = <ThreeDImageContainer className="skymap" width={this.skyWidth} height={this.skyHeight} model_size_bytes={model_size_bytes} model={alert.skymaps['3D'].default.url}/>;
        active3d = "activeButton";
      } else {
        if(this.state.use_skymap) {
          container = <AladinLite className="skymap" />
          activeAladin = "activeButton";
        } else {
          container = <a href={alert.skymapurl} target="_blank" rel="noopener noreferrer">
            <ImageContainer className="skymap" values={alert.skymapurl} />
          </a>
          active2d = "activeButton";
        }
      }

      if (enable_skymap_tabs && !(typeof alert.skymaps['3D'] === 'undefined')) {
        return (
          <div className="content-Container">
            <div className="image-tab-container">
              <button type="button"  onClick={() => this.changeSkymap("2D")} className={"image-container-tab " + active2d}>2D Skymap</button>
              {/*<button type="button"  onClick={() => this.changeSkymap("orbital")} className={"image-container-tab " + activeAladin}>Orbital Skymap</button>*/}
              <button type="button"  onClick={() => this.changeSkymap("3D")} className={"image-container-tab " + active3d}>3D Skymap</button>
            </div>
            {container}
          </div>
        )
      } else {
        return (
          <div className="content-Container">
            {container}
          </div>
        )
      }
  }

  render() { 
    if (this.props.alert) {
      var alert = this.props.alert;
      const alert_name = alert.graceid;
      const main_items = [alert.t_observed.toLocaleString('en-GB', { timeZone: 'UTC', timeZoneName: 'short' }), alert.duration, this.convert_distance(alert), this.get_range_text(alert) , this.get_far_text(alert), alert.mostlikely]
      const source_items = [this.convert_probs(alert.pbbh) + " %", this.convert_probs(alert.pbns) + " %", this.convert_probs(alert.pnsbh) + " %", this.convert_probs(alert.pterrestrial) + " %", this.convert_probs(alert.massgap) + " %", this.convert_probs(alert.hasremnant) + " %"];
      const other_items = [this.get_alert_link(alert), alert.type, this.get_circular_link(alert), alert.role, this.get_instruments_item(alert), alert.t_updated.toLocaleString('en-GB', { timeZone: 'UTC', timeZoneName: 'short' })];
      const subtitle_style =  { color: this.get_alert_subtitle_colour(alert) };

      // var skymap = "";
      var skymap_style = {};

      // if(use_skymap) {
      //   skymap = (
      //     <AladinLite />
      //   )
      //   skymap_style = {height: '650px'};
      // } else {
      //   skymap = (
      //     <a href={alert.skymapurl} target="_blank" rel="noopener noreferrer">
      //       <ImageContainer values={alert.skymapurl} />
      //     </a>
      //   )
      // }

      // Get the type for the subtitle
      return (
        <div className="App">
          <div className='App-main-content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md'>
                  <h1 id='alert-name-title'>{alert_name}</h1>
                  <h3 className='alert-subtitle-header'>Most Likely Origin:   </h3>    <h3 className='alert-subtitle' style={subtitle_style}>{alert.mostlikely}</h3>
                  {this.get_status_subtitle(alert)}
                </div>
              </div>
            </div>
            <div className='container-fluid'>
              <div className='row' style={skymap_style}>
                <div className='col-md col-lg-8'>
                  {/* {skymap} */}
                  {this.get_3d_skymap(alert)}
                </div>
                <div className='Container-row-spacer-md'></div>
                <div className='col-md col-lg-4'><Container items={main_items} title='Important Event Information' fields={main_fields} labels={main_labels}/></div>
              </div>
              <div className='Container-row-spacer'></div>
              <div className='row'>
                {this.check_source_type(alert,source_items,source_fields)}
                <div className='Container-row-spacer-md'></div>
                <div className='col-md'><Container items={other_items} title='Other Information' fields={other_fields} labels={other_labels}/></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if(this.props.loading) {
        return ( <Loading color='black' type='spin' /> )
      } 
      if(this.props.failed) {
        return (<h1>Alert Not Found.</h1>)
      }

      // Default return
      return (<div></div>)
    }
  }
}

export default Alert;
