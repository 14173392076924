import React, {Component} from 'react';
import './About.scss';

import PageSegmentContainer from '../../components/PageSegmentContainer'

class About extends Component {

	aboutUS() {
		// I think this should be split into an "about the app box" and "about us"
		return (
			<p>
				This web-app is developed by astrophysics students at 
				the <a href="https://www.birmingham.ac.uk/research/activity/physics/astronomy/gravitational-wave/gravitational-wave-astronomy.aspx">
				Institute for Gravitational Wave Astronomy - University of 
				Birmingham</a> and the <a href="https://emps.exeter.ac.uk/physics-astronomy/research/astrophysics/">
				Astrophysics Group - University of Exeter</a> who have
				a passion for public engagement and outreach. Between us, our 
				research interests span everything from observational astronomy to
				galaxy evolution, cosmology and instrumentation. Our aim is to
				share our enthusiasm for our research and make it
				accessible to everyone. 
			<br /><br />
			 	We have designed this app to listen to the NASA Gamma Ray 
			 	Coordinates Network (GCN). This is a network of servers which
			 	allow astronomers to communicate events at short notice, 
			 	enabling collaboration and open science, using the <a href="https://emfollow.docs.ligo.org/userguide/"> LIGO/VIRGO open alert user guide.</a> 
			 <br /><br />
			 	The app catches the initial alert, running from the same
			 	data as the astronomy community and displays it in a friendly
			 	format. This means we have the very latest data but does mean
			 	that there may be some false positives. Check to see if the alert is retracted and check the false alarm rate to see how credible a
			 	detection is. 
			</p>
		);
	}

	LIGO() {
		return (
			<p>
				The <a href="https://www.ligo.org">LIGO Scientific 
				Collaboration (LSC)</a> is an international community of scientists 
				working to build earth based Gravitational Wave detectors and 
				use them to learn about the universe. Between us, we represent over 40
				years of cutting edge research. LSC scientists build and operate
				a number of detectors and prototypes, most notably the two 
				LIGO lab detectors.
			<br /><br />
				Each of the <a href="https://www.ligo.caltech.edu/">LIGO</a> detectors is
				formed from two 4 km long arms at right angles to each other.
                                Both of these detectors are situated in USA, one in
				Hanford, Washington State (pictured top), and the other in Livingston,
				Louisiana State (right). These detectors are capable of 
				measuring signals from black holes and neutron stars that warp
				space and time by less than a thousandth the width of a proton.
			<br /><br/>
				Both LIGO detectors are responsible for detecting the first 
				direct observation of a gravitational wave, caused by a pair of
				black holes around a billion light years away in September 2015,
				concluding a 100-year test of Einstein's theory of General Relativity.
			</p>
		);
	}
	VIRGO() {
		return (
			<p>
				<a href="http://www.virgo-gw.eu/">Virgo</a> is a European 
				gravitational wave detector that joined the 
				hunt for new gravitational wave signals shortly after LIGO.
				The detector is slightly smaller than LIGO at 3 km long and is
				situated southwest of Pisa, Italy.
			<br /><br /> 
				By operating three detectors, spaced around the globe, 
				scientists were able to locate the first detection of a
				binary neutron star collision ever in August 2017.
				Due to the accurate location, scientists could observe the 
				collision with telescopes spawning international interest
				and new discoveries about our universe, including the origin of 
				heavy elements such as gold.
			</p>
		);
	}

	Kagra() {
		return (
			<p> 
				<a href="https://gwcenter.icrr.u-tokyo.ac.jp/en/"> Kagra</a> is a 3 km long gravitational wave detector located inside Mt. Ikenoyama, Kamioka, Gifu in Japan. Unlike both LIGO detectors it features cryogenically cooled sapphire mirrors to improve low frequency (below 100 Hz) sensitivity. 
				Its first observing run is expected to be in late 2019, where it will join LIGO and Virgo hunting for gravitational waves. 
			</p>
		);
	}

	render() {

		return (
			<div>
				<div className="container">
					<h1 className="about-title">About</h1>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.aboutUS()} title="About Us" image={'lhoAbove.jpg'} /><p className='imageCredit'>Credit: Caltech/MIT/LIGO Laboratory.</p></div>
				</div>
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.LIGO()} title="LIGO" image={'lloAbove.jpg'} /><p className='imageCredit'>Credit: Caltech/MIT/LIGO Laboratory.</p></div>
				</div>
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.VIRGO()} title="Virgo" image={'virgoAbove.png'} /><p className='imageCredit'>Credit: The Virgo Collaboration</p></div>
				</div>
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer content={this.Kagra()} title="Kagra" image={'KAGRA.jpg'} /><p className='imageCredit'>Credit: Christopher Berry</p></div>
				</div>
			</div>
			)

	}

}

export default About;
