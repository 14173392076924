import {get_obs_status} from '../../obs_status';

import {FETCH_OBSERVATORY_STATUS_BEGIN} from './actionTypes';
import {FETCH_OBSERVATORY_STATUS_SUCCESS} from './actionTypes';
import {FETCH_OBSERVATORY_STATUS_FAILURE} from './actionTypes';

export function fetchObservatoryStatusBegin() {
    return { type: FETCH_OBSERVATORY_STATUS_BEGIN };
}

export function fetchObservatoryStatusSuccess(status) {
    return {
        type: FETCH_OBSERVATORY_STATUS_SUCCESS,
        payload: status,
    }
}

export function fetchObservatoryStatusFailure(err) {
    return {type:FETCH_OBSERVATORY_STATUS_FAILURE, payload: err}
}

export function fetchObsStatusFromLIGO() {

    // Prepare headers for API
    let headers = new Headers();
    const username = "open";
    const password = "alert";
    headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));

    return dispatch => {
        dispatch(fetchObservatoryStatusBegin());
        
        get_obs_status()
        .then(status => {
            dispatch(fetchObservatoryStatusSuccess(status))
        })
        .catch(err => dispatch(fetchObservatoryStatusFailure(err)));
    }
}