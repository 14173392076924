import React, { Component } from 'react';
import Alert from './Alert';
import {fetchAlertFromAPI} from '../../redux/actions/fetchSingleAlert';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class SingleAlert extends Component {

    constructor(props) {
        super(props);
        const gid = this.props.match.params.id;
        this.state = {gid: gid}
    }

    componentWillMount() {
        // Check that the alert is cached and that it has not expired. 
        if (!this.props.alert || this.props.alert.is_expired()) {
            const gid = this.props.match.params.id;
            this.props.get_alert(gid); // Get the alerts.
        }
    }

	render() {
        const { alert, loading, failed } = this.props;
        return (
            <Alert alert={alert} loading={loading} failed={failed} />
        );
	}
}

SingleAlert.propTypes = {
	alert:PropTypes.object,
    retrieve_alert_from_api:PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
    const gid = ownProps.match.params.id;
    return {
        alert: state.singleAlert.alerts[gid],
        loading: state.singleAlert.loading,
        failed: state.singleAlert.failed,
        gid: gid,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    get_alert: gid => dispatch(fetchAlertFromAPI(gid)),
})


const SingleAlertContainer = connect(mapStateToProps, mapDispatchToProps)(SingleAlert);
export default SingleAlertContainer;
