import React, {Component} from 'react';
import './Contact.scss';
import PageSegmentContainer from '../../components/PageSegmentContainer'
import {Mention} from 'react-twitter-widgets'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons'

class Contact extends Component {

	website_button(url) {
		return (
			<a href={url}>
				<button type="button" className="btn btn-warning">
					<FontAwesomeIcon icon={faGlobe} /> &nbsp;
					Website
				</button>
			</a>
		)
	}

	email_button(email_address) {
		return (
			<a href={"mailto:" + email_address}>
				<button type="button" className="btn btn-primary">
					<FontAwesomeIcon icon={faEnvelope} /> &nbsp;
					{email_address}
				</button>
			</a>
		)
	}

	button_container(url, email, twitter) {
		var web_button, twitter_button, email_button;
		if (url)
				web_button = this.website_button(url)
			else
				web_button = ""
		
		if (email)
			email_button = this.email_button(email)
		else
			email_button = ""

		if(twitter)
				twitter_button = <Mention username={twitter} options={{size: 'large'}}/>
			else
				twitter_button = ""
		
		if (url || email || twitter)
			return (
				<div className="button-container">
					{web_button}
					{email_button}
					{twitter_button}
				</div>
			)
		else
			return (<div className="button-container"></div>)
	}

	contactLIGO () {
		//<span className="button-container"><Mention username={'LIGO'} options={{size: 'large'}}/></span>
		//<span className="button-container"><Mention username={'LIGOWA'} options={{size: 'large'}}/> <Mention username={'LIGOLA'} options={{size: 'large'}}/></span>
		//<span className="button-container"><Mention username={'ego_virgo'} options={{size: 'large'}}/></span>
		//<span className="button-container"><Mention username={'KAGRA_PR'} options={{size: 'large'}}/></span>
		//
		return (
			<div>
				<div className="contact-container">
					<p className="contact-container-contents"> For questions regarding gravitational waves and the observatories contact LIGO at one of the links below</p> 
					<div className="contact-container-contents">Ask LIGO a <a href="mailto:questions@ligo.org">question.</a></div>
					<div className="contact-container-contents"><a href="https://www.ligo.caltech.edu/page/contact">LIGO contact infomation.</a>
					</div>
					<div className="contact-container-contents"><a href="http://www.virgo-gw.eu/#contact">Virgo contact  infomation.</a></div>
					
					<div className="contact-container-contents"><a href="https://gwcenter.icrr.u-tokyo.ac.jp/en/plan">Kagra contact infomation.</a></div> 
					
				</div>
			</div>
		);
	}

	contactUniOfExeterAstro() {
		return (
			<div>
				<div className="contact-container">
					<div className="contact-container-contents">Chirp is hosted and maintained by the Astrophysics Group at the University of Exeter. 
					For enquiries about the group, our outreach, and our research, please <a href="https://physics-astronomy.exeter.ac.uk/research/astrophysics/">contact us.</a></div>
				</div>
			</div>

		)
	}

	contactUni() {
		//<span className="button-container"><Mention username={'UoBIGWaves'} options={{size: 'large'}}/></span>
		return (
			<div>
			<div className="contact-container">
				<div className="contact-container-contents">
					Chirp was originally hosted by the Institute for Gravitational Wave Astronomy at the University of Birmingham. 
					For enquiries about the institute, please <a href="http://www.sr.bham.ac.uk/contact.php">contact us.</a>
				</div>
			</div>
			</div>
		);
	}

	contactDevs(){
		return (
			<div>
				<div className="contact-container">
					<div className="contact-container-contents"> 
						Got a question about the website? Found a bug?<br />
						You can contact the current maintainer (Dr Sam Morrell) using the button below: <br /><br />
						<div className="contact-container-contents"> 
							<span className="contact-name">Sam Morrell (Project Maintainer) </span>
							{this.button_container("https://sammorrell.co.uk/", "s.a.f.morrell@exeter.ac.uk", "smorrell")}
						</div>

						<hr class="contact-rule" />
						The following are the past and present contributors to Chirp: <br /><br />

						<div className="contact-container-contents"> 
							<span className="contact-name">Sam Cooper</span>
							{this.button_container(undefined, undefined, undefined)}
						</div>

						<div className="contact-container-contents"> 
							<span className="contact-name">Aaron Goodwin-Jones</span>
							{this.button_container("https://aaronwjones.com/", undefined, "phyaaron")}
						</div>

						<div className="contact-container-contents"> 
							<span className="contact-name">Jiří (George) Smetana</span>
							{this.button_container("http://www.sr.bham.ac.uk/whoswho.php?id=18", undefined, undefined)}
						</div>
						<div className="contact-container-contents"> 
							<span className="contact-name">Riccardo Buscicchio</span>
							{this.button_container("https://en.unimib.it/riccardo-buscicchio", undefined, undefined)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {

		return (
			<div>
				<div className="container">
					<h1 className="contact-title">Contact Us</h1>
				</div>

				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm contactUsStyle"><PageSegmentContainer className="contactUsStyle" content={this.contactLIGO()} title="Contact the LIGO - Virgo Collaboration (LVC)" image={'ligoMirror.jpg'} /><p className='imageCredit'>Credit: Matt Heintze / Caltech / MIT / LIGO Lab.</p></div>
				</div>
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer className="contactUsStyle" content={this.contactUniOfExeterAstro()} title="Contact the University of Exeter Astrophysics Group" image={'uoe_streatham_aerial.jpg'} /><p className='imageCredit'>Credit: University of Exeter / Danny Cooke.</p></div>
				</div>
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer className="contactUsStyle" content={this.contactUni()} title="Contact the Institute for Gravitational Wave Astronomy" image={'bham.jpg'} /><p className='imageCredit'>Credit: University of Birmingham.</p></div>
				</div>
				<div className='Container-row-spacer'></div>
				<div className="container">
					<div className="col-sm"><PageSegmentContainer className="contactUsStyle" content={this.contactDevs()} title="Contact the Developers" image={'contact_bg.jpg'} /></div>
				</div>
				<div className='Container-row-spacer'></div>
			</div>
			)

	}

}

export default Contact;