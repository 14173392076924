import {get_alert} from '../../alerts';

import {FETCH_SINGLE_ALERT_BEGIN} from './actionTypes';
import {FETCH_SINGLE_ALERT_SUCCESS} from './actionTypes';
import {FETCH_SINGLE_ALERT_FAILURE} from './actionTypes';

export function fetchSingleAlertBegin() {
    return { type: FETCH_SINGLE_ALERT_BEGIN };
}

export function fetchSingleAlertSuccess(alert, id) {
    return {
        type: FETCH_SINGLE_ALERT_SUCCESS,
        payload: alert,
        gid: id,
    }
}

export function fetchSingleAlertFailiure(err) {
    return {type:FETCH_SINGLE_ALERT_FAILURE, payload: err}
}

export function fetchAlertFromAPI(alert_id = "") {
    return dispatch => {
        dispatch(fetchSingleAlertBegin());
        
        get_alert(alert_id)
        .then(alert => {
            dispatch(fetchSingleAlertSuccess(alert, alert_id))
        })
        .catch(err => dispatch(fetchSingleAlertFailiure(err)));
    }
}