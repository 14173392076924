import {FETCH_LATEST_ALERT_BEGIN} from './actionTypes.js';
import {FETCH_LATEST_ALERT_SUCCESS} from './actionTypes.js';
import {FETCH_LATEST_ALERT_FAILURE} from './actionTypes.js';
import { get_latest_alert } from '../../alerts';


export function fetchLatestAlertBegin() {
    return { type: FETCH_LATEST_ALERT_BEGIN };
}

export function fetchLatestAlertSuccess(alert) {
    return {
        type: FETCH_LATEST_ALERT_SUCCESS,
        payload: alert,
    }
}

export function fetchLatestAlertFaliure(err) {
    return {type:FETCH_LATEST_ALERT_FAILURE, payload: err}
}


export function fetchLatestAlertFromAPI() {
    return dispatch => {
        dispatch(fetchLatestAlertBegin());
        
        get_latest_alert()
        .then(alert => {
            console.log('latest', alert)
            dispatch(fetchLatestAlertSuccess(alert))
        })
        .catch(err => dispatch(fetchLatestAlertFaliure(err)));
    }
}