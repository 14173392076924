// single alert reducer
// Import action type strings to avoid typos

import {FETCH_SINGLE_ALERT_BEGIN, FETCH_SINGLE_ALERT_SUCCESS, FETCH_SINGLE_ALERT_FAILURE} from '../actions/actionTypes';

const initialState = {
	loading: true,
	error: null,
	failed: false,
	alerts : {},
}


export default function singleAlert(state = initialState, action) {
	 
	 switch(action.type) {
	 	case FETCH_SINGLE_ALERT_BEGIN:
	 		// We want to mark this as loading and reset errors as we're starting again. 
	 		return {
	 			...state, 
	 			loading: true, 
				error: null,
				failed: false,
	 		};
	 	case FETCH_SINGLE_ALERT_SUCCESS:
	 		// This means by some miracle that we're been able to get data from
			 // the API and have a success
			var graceid = action.gid;
	 		return {
	 			...state,
	 			loading: false,
				error: null,
				failed: false,
	 			alerts: Object.assign({}, state.alerts, {
					[graceid] : action.payload,
				})
	 		};
	 	case FETCH_SINGLE_ALERT_FAILURE:
	 		// This means that we haven't been able to get data from the API and should
	 		// return a error
	 		return {
	 			...state, 
				loading: false, 
				failed: true,
	 		};
	 	default:
	 		// we ALWAYS have to have a default case so we shall just return the initial state.
	 		return state
	 }
	
}