import {combineReducers} from 'redux';

import singleAlert from './singleAlert';
import alertsList  from './alertsList';
import latestAlert from './latestAlert';
import obsStatus from './obsStatus';

const combinedReducers = combineReducers({
	alertsList,
	latestAlert,
	singleAlert,
	obsStatus,
});

export default combinedReducers; 