import React, { Component } from 'react';
import './PageSegmentContainer.scss'; // Import css modules stylesheet as styles

class PageSegmentContainer extends Component {

	render() {
        if (this.props.image) {
            return(
                <div className='row'>
                    <div className="page-segment">
                        <h3 className="page-segment-title">{this.props.title ? this.props.title : ""}</h3>
                        <div className="page-segment-contents with-image">{this.props.content ? this.props.content : ""}</div>
                        <div className="page-segment-image-container">
                            <img className="page-segment-image" src={this.props.image} alt={this.props.image} />
                            <div className="page-segment-image-overlay"></div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className='row'>
                    <div className="page-segment">
                        <h3 className="page-segment-title">{this.props.title ? this.props.title : ""}</h3>
                        <div className="page-segment-contents">{this.props.content ? this.props.content : ""}</div>
                    </div>
                </div>
            );
        }
	}

}

export default PageSegmentContainer;