import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import './index.scss';
import './scss/bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './serviceWorker';

// Page Compnents
import ScrollToTop from './App/ScrollToTop';
import Menu from './App/Menu';
import Footer from './App/Footer';

// Pages
import Home from './App/Home/Home';
import About from './App/About/About';
import Contact from './App/Contact/Contact';
import AlertsList from './App/AlertsList/AlertsList';
import SingleAlert from './App/Alert/SingleAlert';
import LatestAlert from './App/Alert/LatestAlert';
import Accessibility from './App/Accessibility/Accessibility';

import ChirpMoved from "./App/ChirpMoved";

const routing = (
  <Provider store={store}>
    <Router>
      <ScrollToTop>
        <div id='content'>
          <div className='footer-spacer'>
            <header className="App-header">
              <Menu />
            </header>
            <ChirpMoved />

            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/alert/:id" component={SingleAlert} />
            <Route exact path="/latest" component={LatestAlert} />
            <Route exact path="/alerts" component={AlertsList} />
            <Route exact path="/accessibility" component={Accessibility}/>
          </div>
          <Footer></Footer>
        </div>
      </ScrollToTop>
    </Router>
  </Provider>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
