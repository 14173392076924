// Here we'll store all the strings for each action 

// GET ALERT LISTS

export const FETCH_ALERT_LIST_BEGIN = 'FETCH_ALERT_LIST_BEGIN';
export const FETCH_ALERT_LIST_SUCCESS = 'FETCH_ALERT_LIST_SUCCESS';
export const FETCH_ALERT_LIST_FAILURE = 'FETCH_ALERT_LIST_FAILURE';
export const INVALIDATE_ALERT_LIST = 'INVALIDATE_ALERT_LIST';

// GET LATEST ALERT 

export const FETCH_LATEST_ALERT_BEGIN = 'FETCH_LATEST_ALERT_BEGIN';
export const FETCH_LATEST_ALERT_SUCCESS = 'FETCH_LATEST_ALERT_SUCCESS';
export const FETCH_LATEST_ALERT_FAILURE = 'FETCH_LATEST_ALERT_FAILURE';

// FETCH SINGLE ALERT

export const REQUEST_SINGLE_ALERT = 'REQUEST_SINGLE_ALERT';
export const FETCH_SINGLE_ALERT_BEGIN = 'FETCH_SINGLE_ALERT_BEGIN';
export const FETCH_SINGLE_ALERT_SUCCESS = 'FETCH_SINGLE_ALERT_SUCCESS';
export const FETCH_SINGLE_ALERT_FAILURE = 'FETCH_SINGLE_ALERT_FAILURE';

// FETCH OBSERVATORY STATUS

export const REQUEST_OBSERVATORY_STATUS = 'REQUEST_OBSERVATORY_STATUS';
export const FETCH_OBSERVATORY_STATUS_BEGIN = 'FETCH_OBSERVATORY_STATUS_BEGIN';
export const FETCH_OBSERVATORY_STATUS_SUCCESS = 'FETCH_OBSERVATORY_STATUS_SUCCESS';
export const FETCH_OBSERVATORY_STATUS_FAILURE = 'FETCH_OBSERVATORY_STATUS_FAILURE';