import React, {Component} from 'react';
import './imageContainer.scss';

class ImageContainer extends Component {

	render() {
		return (

			<div className='image-container'>
				<img className='skymap' alt='Skymap' src={this.props.values}></img>
			</div>
		)
	}
}

export default ImageContainer;