import {createStore, applyMiddleware} from 'redux'
//import { composeWithDevTools } from 'redux-devtools-extension';
import combinedReducers from './reducers/combineReducers';
import thunk from "redux-thunk"

// import ALL_ACTIONS from './actions/combineActions.js'

// const initialState = {
// 		singleAlert: {	
// 			loading:false,
// 			error:null,
// 			alert_id:null,
// 			alert_contents:[],
// 			id: []
// 		},
// 		latestAlert: {
// 			loading:false,
// 			error:null,
// 			alert_id:null,
// 			alert_contents:[],
// 			id: [],
// 		},
// 		alertsList:  {	
// 			loading:false, 
// 			error:null, 
// 			list_contents:[]
// 		}
// }

// const composedEnhancer = compose(applyMiddleware(thunk));

// export const store = createStore(combinedReducers, {}, composeWithDevTools( applyMiddleware(thunk) ) );
export const store = createStore(combinedReducers, {}, applyMiddleware(thunk));