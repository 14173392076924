// latest alert reducer

// Import action type strings to avoid typos

import {FETCH_LATEST_ALERT_BEGIN} from '../actions/actionTypes.js';
import {FETCH_LATEST_ALERT_SUCCESS} from '../actions/actionTypes.js';
import {FETCH_SINGLE_ALERT_FAILURE} from '../actions/actionTypes.js';


const initialState = {
	loading: true,
	error: null,
	failed: false,
	latest_alert: null,
}

export default function latestAlert(state = initialState, action) {
	switch(action.type) {
		case FETCH_LATEST_ALERT_BEGIN:
			// need to mark as loading and set all the errors to nulls
			return {
				loading: true, 
				error: null,
				failed: false, 
				latest_alert: null,
			};
		case FETCH_LATEST_ALERT_SUCCESS:
			// This means by some miracle that we're been able to get data from
			// the API and have a success
			var alert = action.payload;
			return {
				...state,
				loading: false,
				error: null,
				failed: false,
				latest_alert: alert,
			};
		case FETCH_SINGLE_ALERT_FAILURE:
			// WE'VE FAILED! So throw an error
			return {
				...state, 
				loading:false,
				error:action.payload,
				failed: true,
				latest_alert: null,
			};
		default:
			// Always have to have a default so we shall just return the initial state
			return state
	}	

}