import {Component} from 'react';
import { withRouter } from "react-router";

// This component wraps the page content and returns
// the scroll to the top of the page after the route changes. 
// Source: https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
  export default withRouter(ScrollToTop)